@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

body {
  font-size: 92%;
}

div.orbit {
  margin-top:0;
  margin-left: auto;
  margin-right: auto;
}

.top-orbit img {
  width: 100% !important;
  border-bottom: 1px solid #777;
}

.off-canvas-wrapper {
  background-color:#676666;
}

h1, h2 {
  text-transform:uppercase;
  padding-bottom:0.3rem;
  border-bottom: 1px solid $primary-color;
  font-size:1.45rem;
}


h3 {
    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: normal;
    font-style: normal;
    color: inherit;
    text-rendering: optimizelegibility;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    line-height: 1.4;
    font-size: 1.4rem;
}

h5 {
  font-weight:bold;
}

.off-canvas-list {
  list-style:none;
  padding:0;
  margin:0;
  background-color:#676666;
}

.off-canvas-list li {
  padding:0;
  margin:0;
  border-bottom: 1px solid $white;
}

.off-canvas-list a {
  background-color:$off-canvas-link-background;
  color:$white;
  padding:8px 10px;
  font-size:0.9rem;
  display:block;
}

.off-canvas-list a:hover,
.off-canvas-list a.active {
  background-color: $off-canvas-link-background-hover;
}

.row.row-small {
  max-width:68rem;
}

.vspace {
  min-height:3.5rem;
}

.repeater-vspace {
  min-height: 2.0rem;
}

.sidebar {
  padding-top: 2.5rem;
}

.sidebar h4 {
  font-size: 1.2rem;
  font-weight:600;
}

.vspace hr {
  margin-top:1.5rem;
  margin-bottom: 3.0rem;
  border-color: $primary-color;
}

div.zitat {
  padding-top: 2.5rem;
  font-size:110%;
}

.repeater hr {
  border-style: dotted;
}

.callout.large {
    padding:2.5rem 1rem;
}

div.zitat span.quote.start {
  font-size: 3rem;
  color: $primary-color;
  line-height:0;
  position:relative;
  top:-1rem;
}

div.zitat span.quote.end {
  font-size: 3rem;
  color: $primary-color;
  line-height:0rem;
  position:relative;
  top: 1.2rem;
}


.teaser a.morelink {
  text-align: left;
  line-height: 1.3rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  font-weight:600;
}

.teaser a.morelink:after {
  content: ' >'
}

.row.top-bar-small {
  padding-bottom:0.5rem;
  padding-top: 0.5rem;
  background-color: #EEEEEE;
  padding-bottom: 1.2rem;
}

.menu-button-wrapper {
  text-align:right;
  padding-right:0;
}

.small-logo-wrapper img {
  max-height: 50px;
}

.top-bar.top-bar-lower {
  padding-top:0;
  padding-bottom:0;
  background:#6B9225;
  height: 50px;
  border-bottom: 1px solid #56751E;
}

.top-bar.top-bar-lower .top-bar-right {
  position:relative;
}

.top-bar.top-bar-lower .top-bar-left {
  position:absolute;
  display:none;
}

.top-bar.top-bar-lower .top-bar-right ul {
  margin-top:0.8rem;
  background: transparent !important;
}

.top-bar.top-bar-lower .top-bar-right ul li a {
  font-size:1.2rem;
  font-weight:300;
  display:inline-block;
  border-right: 1px solid #FFFFFF;
}

.top-bar.top-bar-lower .top-bar-right ul li:last-child a {
  border-right:none;
}

.top-bar.top-bar-lower .top-bar-right ul a:hover,
.top-bar.top-bar-lower .top-bar-right ul a.active {
  color:#EEEEEE;
}

.top-bar.top-bar-lower .menu > li:not(.menu-text) > a {
    display: block;
    padding: 0.20rem 1.0rem 0.20rem 1.0rem;
    line-height: 1;
    font-size:1.0rem;
    color:#FFF;
}

.top-bar.top-bar-lower .menu > li:first-child:not(.menu-text) > a {
  background-image:none;
}

.top-bar-upper {
  min-height: 85px;
  background-color:#EEEEEE;
  border-bottom: 1px solid #56751E;
}

.top-bar-upper .top-bar-right {
  padding-right:0.5rem;
}

.top-bar.top-bar-upper ul {
    background-color: transparent;
}

.top-bar.top-bar-upper ul li {
  
}

.top-bar.top-bar-upper ul a {
  color: #52701C;
  font-size:0.8rem;
  padding:4px;
}

.top-bar li.menu-text img {
  position:relative;
  top:-0.5rem;
}

.bu {
  padding-top: 0.5rem;
}

.bu span.name,.bu span.funktion {
  display:block;
}

.bu span.funktion {
  color:$medium-gray;
}

.partner-logos img {
  margin-right: 11px;
}


.partner {
  margin-top: 2.5rem;
}

.partner-logos img:first-child {
  margin-right:12px;
}

.partner-logos img:last-child {
  margin-right:0;
}

.footer {
  background-color:#353334;
  margin-top:2.5rem;
  color:$white;
}

.footer h5 {
  text-transform:uppercase;
  font-size: 1.0rem;
}

.footer h5 span {
  text-transform:normal;
  font-weight:bold;
}

.contact-data {
  list-style:none;
  padding-left:0;
  margin-left:0;
}

.contact-data li {
  padding: 1px 0 1px 35px;
  background-repeat:no-repeat;
  background-position: 0 2px;
}

.contact-data li.phone {
  background-image: url(../img/icon-phone.jpg);
}

.contact-data li.fax {
  background-image: url(../img/icon-fax.jpg);
}

.contact-data li.mail {
  background-image: url(../img/icon-mail.jpg);
}

.contact-data li.web {
  background-image: url(../img/icon-web.jpg);
}

.contact-data li.remote {
  background-image: url(../img/icon-remote.jpg);
}

.contact-data li.form {
  background-image: url(../img/icon-form.jpg);
}

button.menutoggle {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 66px;
    padding-left: 30px;
    padding-right: 30px;
}

div.block-image {
  float:none;
  background:none;
  margin-bottom:1.0rem;
}

div.left-image {
  float:left;
  margin-right:1.0rem;
  margin-bottom: 1.0rem;
  max-width:400px;
}

div.right-image {
  float:right;
  margin-left:1.0rem;
  margin-bottom: 1.0rem;
  max-width:400px;
}

.repeater {
  padding-bottom: 1.5rem;
}

.repeater:last-child {
  border-bottom: none;
}

hr.hr-repeater-spacer {
    border-style: none none solid;
    border-color: -moz-use-text-color -moz-use-text-color $light-gray;
    margin: 2.25rem auto 0.0rem auto;
}

iframe.map {
  max-width:100%;
}

body:not(.page-id-1024) iframe.map {
  display:none;
}

div.mobile-image.mobile-image-top {
  margin-bottom: 1.0rem;
}

.bu-repeater {
  font-size:0.9rem;
  margin-top:0.75rem;
}

div.morelink {
  margin:0rem;
  padding:0.75em;
  background:#597B18;
  margin-bottom: 1.5rem;
}

div.morelink p {
  padding:0;
  margin:0;
}

div.morelink a {
  color: $white;
  font-weight:600;
  font-size:0.9rem;
  line-height:1.3rem;
  display:block;
}

.teaser .morelink a:after {
  content: ' >'
}

.teaser h5 {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

body.page-id-1 h1 {
  text-transform: none;
font-size: 1.2rem;
padding-left: 5px;
font-weight: bold;
margin-bottom: 20px;
}

@media screen and (min-width: 40em) {
 
 div.orbit {
  margin-top:5.5rem;
}
 
.mm-image img {
  margin-top: 73px;
  border: 1px solid #DDD;
} 
 
.teaser-text {
  min-height:200px;
}
  .callout.large {
    padding:3rem 3rem;
  }
  div.zitat p {
    text-indent:-1.625rem;
    padding-left:1.9rem;
    padding-right:1rem;
    line-height:1.5rem;
  }
  
  div.zitat a.morelink {
    display:inline-block;
    margin-left:1.9rem;
    margin-top:1.5rem;
    font-size:100%;
  }
  h5 {
    font-weight:500;
  }
  .footer h5 {
    text-transform:uppercase;
    font-size: 1.15rem;
  }
  iframe.map {
    max-width:350px;
  }
  .template-basic-page-w-sidebar .repeater,
  .template-basic-page-remote-control .repeater,
  .template-contact-page form {
    padding-right:2.5rem;
  }
  
  .contactForm {
    padding-top: 2.5rem;
  }
  
  div.orbit {
    margin-top:0rem;
  }
  
  .top-bar ul li a {
    color:#666666;
  }
  .top-bar.navigation-main ul li.active a {
    color:#EEEEEE !important;
  }
  
 .partner .orbit-previous, .partner .orbit-next {
    display:none;
}

}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {

  border-left: 2px solid #bababa;
  border-top: 2px solid #bababa;
  font-size:0.925rem;
}

body.page-id-1024 fieldset {
  border: 1px solid #bababa;
  padding: 12px;
  background-color:#f5f5f5;
  min-height: 635px;
}

body.page-id-1024 legend {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top:0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.85rem;
  color:#FFF;
  background-color:#6B9225;
}

body.page-id-1024 fieldset label {
  font-weight: bold;
}

body.page-id-1024 input[placeholder], body.page-id-1024 [placeholder], body.page-id-1024 *[placeholder] {
    color: #222 !important;
    font-weight:normal;
}

div.partnerlogo .image-wrap {
  border: 1px solid #BBBBBB;
  padding:4px 0;
  margin-bottom: 25px;
-webkit-box-shadow: 2px 2px 3px 1px rgba(173,170,173,1);
-moz-box-shadow: 2px 2px 3px 1px rgba(173,170,173,1);
box-shadow: 2px 2px 3px 1px rgba(173,170,173,1);
}

div.referenz .referenz-wrap {
  border: 1px dotted #DDDDDD;
  padding:10px;
  margin-bottom: 25px;
  min-height:355px;
}

.referenz-item h3 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-size:1.2rem;
}

.referenz-item .funktion,
.referenz-item .url {
  display:block;
  font-size:0.75rem;
}

.referenz-item .image {
  float:left;
  margin-right:12px;
  margin-bottom: 4px;
  width: 125px;
}

.referenz-item .referenz-content {
  margin-top: 1.5rem;
  overflow:hidden;
}

.referenz-item .referenz-content h3 {
  margin-top: 0;
  font-size: 0.95rem;
  font-weight: bold;
  margin-bottom: 0.5rem !important;
}

.referenz-item .referenz-content p {
  font-size: 0.9rem;
  line-height: 1.3rem !important;
}

div.slide-wrap {
  position: relative;
}

div.seo {
  margin-top: 3.0rem !important;
  color: #BBB;

}

div.contact-item {
  margin-top: 1.5rem;
}

div.contact-item .row-text {
  margin-top: 12px;
}

div.contact-item .name {
  text-transform: uppercase;
}

div.contact-item .image {
  border-bottom: 1px solid #CCC;
}

div.contact-item .descr p {
  white-space:no-wrap;
}

.repeater .repeater-headline {
  margin-bottom: 1.0rem;
}
